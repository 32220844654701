<template>
  <page-section >
    <v-sheet :color="wsATTENTION" class="wsRoundedHalf pa-12" dark>
      <h3 class="mt-3 text-center"
          :style="`font-size : ${!SM ? 34 : 19}px`"
      >
        {{ $t('homepage.sections.tryNow.title') }}
      </h3>

      <div class="d-flex justify-center mt-4 ">
        <ws-button
            :to="localeLink('register')"
            color="white"  outlined label="homepage.pricing.tryForFree" />
      </div>


      <v-img class="mt-6"  src="@/assets/illustrations/trial.png" />

    </v-sheet>
  </page-section>
</template>

<script>
export default {
  name: "homeSectionCases",
  components : {},
}
</script>

<style scoped>

</style>